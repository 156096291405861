<template>
  <div>
    <Header/>

    <div id="level">
      <div class="inner w1400">
        <div class="bg">
          <div class="bg2" style="background-image: url(@/assets/img/feedback/deco.png)"></div>
        </div>
        <div class="item">
          <div class="top_head">
            <img src="img/icon/icon_title_level.svg" alt="">
            <h4>會員分級規章及權益</h4>
          </div>
          <div class="tagbox">
            <a class="tag_link" :class="{active : nowTag === 0}" @click="nowTag = 0">
              <img src="@/assets/img/icon/level_1-2.svg" alt="" class="color_line">
              <img src="@/assets/img/icon/level_1-1.svg" alt="" class="line">
              <img src="@/assets/img/icon/level_1.svg" alt="" class="color">
              <h6 class="txt-bold">一般會員</h6>
            </a>
            <a class="tag_link" :class="{active : nowTag === 1}" @click="nowTag = 1">
              <img src="@/assets/img/icon/level_2-2.svg" alt="" class="color_line">
              <img src="@/assets/img/icon/level_2-1.svg" alt="" class="line">
              <img src="@/assets/img/icon/level_2.svg" alt="" class="color">
              <h6 class="txt-bold">VIP會員</h6>
            </a>

            <a class="tag_link" :class="{active : nowTag === 2}" @click="nowTag = 2">
              <img src="@/assets/img/icon/level_3-2.svg" alt="" class="color_line">
              <img src="@/assets/img/icon/level_3-1.svg" alt="" class="line">
              <img src="@/assets/img/icon/level_3.svg" alt="" class="color">
              <h6 class="txt-bold">VVIP會員</h6>
            </a>
          </div>
          <div class="content" :class="{active : nowTag === 0}">
            <div class="info">
              <p class="title">資格使用期間</p>
              <p class="inside">從升等日開始 <span style="color:#218E87">365</span> 天計算</p>
              <p class="title">資格續用條件</p>
              <p class="inside">
                一年累積消費滿&nbsp;
                <span style="color:#218E87">TWD$8,000</span>&nbsp;
                可自動續VIP會員資格
              </p>
            </div>
            <ul class="member_info">
              <p class="title">VIP 會員回饋</p>
              <li>
                <img src="@/assets/img/member_level/level_coupon.svg" alt="">
                <h5>專屬折價劵 <img src="@/assets/img/icon/icon_exclamation.svg" alt=""></h5>
                <small>不定期發送至我的優惠</small>
              </li>
              <li>
                <img src="@/assets/img/member_level/level_gift.svg" alt="">
                <h5>生日折價劵$500 <img src="@/assets/img/icon/icon_exclamation.svg" alt=""></h5>
                <small>生日當月1號發送至我的優惠</small>
              </li>
              <li>
                <img src="@/assets/img/member_level/level_shippingfree.svg" alt="">
                <h5>免運優惠每年5次<img src="@/assets/img/icon/icon_exclamation.svg" alt=""></h5>
                <small>可至我的優惠裡查詢可使用次數</small>
              </li>
            </ul>
            <ul class="use_info">
              <p class="title">回饋使用限制</p>
              <li>
                <p>1. 專屬優惠代碼不能與會員生日購物金及其他優惠同時使用 ／ 生日購物金可與其他優惠合併使用(含免運優惠)。</p>
                <p> 2. 一般會員與VIP會員如使用企業優惠價之訂單無法累積消費計算紅利回饋。</p>
                <p>3. 一般會員與VIP會員之所有回饋 (EX: 優惠代碼、生日購物金、免運..等)，皆無法適用於企業優惠價之訂單。</p>
              </li>
            </ul>
          </div>
          <div class="content" :class="{active : nowTag === 1}">
            <div class="info">
              <p class="title">資格使用期間</p>
              <p class="inside">從升等日開始 <span style="color:#218E87">900</span> 天計算</p>
              <p class="title">資格續用條件</p>
              <p class="inside">
                一年累積消費滿&nbsp;
                <span style="color:#218E87">TWD$8,000</span>&nbsp;
                可自動續VIP會員資格
              </p>
            </div>
              <ul class="member_info">
                <p class="title">VIP 會員回饋</p>
                <li><img src="@/assets/img/member_level/level_coupon.svg" alt="">
                  <h5>專屬折價劵 <img src="@/assets/img/icon/icon_exclamation.svg" alt=""></h5>
                  <small>不定期發送至我的優惠</small>
                </li>
                <li><img src="@/assets/img/member_level/level_gift.svg" alt="">
                  <h5>生日折價劵$500 <img src="@/assets/img/icon/icon_exclamation.svg" alt=""></h5>
                  <small>生日當月1號發送至我的優惠</small>
                </li>
                <li><img src="@/assets/img/member_level/level_shippingfree.svg" alt="">
                  <h5>免運優惠每年5次<img src="@/assets/img/icon/icon_exclamation.svg" alt=""></h5>
                  <small>可至我的優惠裡查詢可使用次數</small>
                </li>
              </ul>
              <ul class="use_info">
                <p class="title">回饋使用限制</p>
                <li>
                  <p>1. 專屬優惠代碼不能與會員生日購物金及其他優惠同時使用 ／ 生日購物金可與其他優惠合併使用(含免運優惠)。</p>
                  <p> 2. 一般會員與VIP會員如使用企業優惠價之訂單無法累積消費計算紅利回饋。</p>
                  <p>3. 一般會員與VIP會員之所有回饋 (EX: 優惠代碼、生日購物金、免運..等)，皆無法適用於企業優惠價之訂單。</p>
                </li>
              </ul>
          </div>
          <div class="content" :class="{active : nowTag === 2}">
            <div class="info">
              <p class="title">資格使用期間</p>
              <p class="inside">從升等日開始 <span style="color:#218E87">100000</span> 天計算</p>
              <p class="title">資格續用條件</p>
              <p class="inside">
                一年累積消費滿&nbsp;
                <span style="color:#218E87">TWD$8,000</span>&nbsp;
                可自動續VIP會員資格
              </p>
            </div>
            <ul class="member_info">
              <p class="title">VIP 會員回饋</p>
              <li><img src="@/assets/img/member_level/level_coupon.svg" alt="">
                <h5>專屬折價劵 <img src="@/assets/img/icon/icon_exclamation.svg" alt=""></h5>
                <small>不定期發送至我的優惠</small>
              </li>
              <li><img src="@/assets/img/member_level/level_gift.svg" alt="">
                <h5>生日折價劵$500 <img src="@/assets/img/icon/icon_exclamation.svg" alt=""></h5>
                <small>生日當月1號發送至我的優惠</small>
              </li>
              <li><img src="@/assets/img/member_level/level_shippingfree.svg" alt="">
                <h5>免運優惠每年5次<img src="@/assets/img/icon/icon_exclamation.svg" alt=""></h5>
                <small>可至我的優惠裡查詢可使用次數</small>
              </li>
            </ul>
            <ul class="use_info">
              <p class="title">回饋使用限制</p>
              <li>
                <p>1. 專屬優惠代碼不能與會員生日購物金及其他優惠同時使用 ／ 生日購物金可與其他優惠合併使用(含免運優惠)。</p>
                <p> 2. 一般會員與VIP會員如使用企業優惠價之訂單無法累積消費計算紅利回饋。</p>
                <p>3. 一般會員與VIP會員之所有回饋 (EX: 優惠代碼、生日購物金、免運..等)，皆無法適用於企業優惠價之訂單。</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <router-link
        :to="{ name: 'MemberData' }"
        class="back_btn">
          返回會員資料
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';

import '@/assets/scss/member_level.scss';

export default {
  name: 'MemberLevel',
  components: {
    Header,
  },
  data() {
    return {
      nowTag: 0,
    };
  },
};
</script>
